const mobileRouter = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/themes_mobile/page/home'),
        children:[
            
        ]
    },
    {
        path: '/newsRelease',
        name: 'newsRelease',
        component:() => import('@/views/themes_mobile/page/newsRelease'),
    },
]

export default mobileRouter
