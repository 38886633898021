const webRouter = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/themes/home'),//模因首页
        children:[
            //page 默认页
            {
                path: '/',
                name: 'homePage',
                component:() => import('@/views/themes/page/homePage.vue'), // 默认页
            },
            {
                path: '/text',
                name: 'text',
                component:() => import('@/views/themes/page/text.vue'), // 默认页
            },
            {
                path: '/about',
                name: 'about',
                component:() => import('@/views/themes/page/about.vue'), // 默认页
            },
        ],
        
    },
    {
        path: '/newsRelease',
        name: 'newsRelease',
        component:() => import('@/views/themes_mobile/page/newsRelease'),
    },
]

export default webRouter
